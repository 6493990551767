/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js', {
    ready() {
      console.log('Service Worker is active.');
    },
    registered() {
      console.log('Service Worker has been registered.');
    },
    updated() {
      console.log('New content is available; deleting Service Worker cache...');
      caches.keys().then((keyList) => {
        return Promise.all(
          keyList.map((key) => caches.delete(key)), // すべてのキャッシュを削除
        );
      }).then(() => {
        window.location.reload(); // キャッシュ削除後にリロード
      });
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}