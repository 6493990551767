import { Member } from '@/models/member'

export class Community {
  public static createFromJson(json: any): Community {
    const members = (json.members ?? []).map((member: any) => Member.createFromJson(member))
    return new Community(
      json.id,
      json.logo ?? null,
      json.name,
      json.mode,
      json.rate,
      json.memo,
      json.games ?? 0,
      json.shared ?? 0,
      members,
      new Date(`${json.createdAt}Z`),
      new Date(`${json.updatedAt}Z`),
      json.cnt_user ?? 0,
      json.cnt_member ?? 0,
      json.cnt_game ?? 0,
    )
  }

  constructor(
    public id: string,
    public logo: string | null,
    public name: string,
    public mode: number,
    public rate: number,
    public memo: string,
    public games: number,
    public shared: number,
    public members: Member[],
    public createdAt: Date,
    public updatedAt: Date,
    public cnt_user: number,
    public cnt_member: number,
    public cnt_game: number,
  ) {}
}

export type CreateCommunityInput = {
  name: string
  mode: number
  rate: number
  memo?: string
}
export type UpdateCommunityInput = {
  name?: string
  mode?: number
  rate?: number
  memo?: string
}
