import { Community } from './community'
import { GameField } from './gameField'

export class User {
  public static createFromJson(json: any): User {
    const communities = (json.communities ?? []).map((it: any) => Community.createFromJson(it))
    return new User(
      json.id,
      json.snsId,
      json.email,
      json.name,
      json.picture,
      json.isAdmin,
      json.isFacebook,
      json.isGoogle,
      json.isInstagram,
      json.isLine,
      json.isX,
      json.win ?? 0,
      json.los ?? 0,
      json.eve ?? 0,
      json.yakuman ?? 0,
      json.yakitori ?? 0,
      json.total,
      (json.gameFields ?? []).map((it: any) => GameField.createFromJson(it)),
      communities,
    )
  }

  constructor(
    public id: string,
    public snsId: string,
    public email: string | null,
    public name: string,
    public picture: string | null,
    public isAdmin: boolean,
    public isFacebook: boolean,
    public isGoogle: boolean,
    public isInstagram: boolean,
    public isLine: boolean,
    public isX: boolean,
    public win: number,
    public los: number,
    public eve: number,
    public yakuman: number,
    public yakitori: number,
    public total: number | null,
    public gameFields: GameField[],
    public communities: Community[],
  ) {}
}

export type UserInput = {
  snsId?: string | null
  email?: string | null
  name?: string
  picture?: string
  isGoogle?: boolean
  idToken?: string
}
